export const SHIFT_DISCOVERY_PATH = "shift-discovery";

export const SHIFT_DISCOVERY_LIST_PATH = `shift-discovery/list`;
export const SHIFT_DISCOVERY_MAP_PATH = `shift-discovery/map`;

export const SHIFT_DISCOVERY_CALENDAR_PATH = `calendar`;
export const SHIFT_DISCOVERY_FILTERS_PATH = `filters`;
export const SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH = `location-permissions`;

/**
 * The modal system consists of two main types of modals:
 * 1. Workplace Modals - For displaying workplace related information
 * 2. Shift Modals - For displaying shift related information
 *
 * These modals can be rendered in different contexts:
 * - From the list view (/shift-discovery/list/...)
 * - From the map view (/shift-discovery/map/...)
 * - On top of other modals (nested modals)
 *
 * The path structure reflects this hierarchy:
 * Base path: /shift-discovery
 * Context path: /list or /map
 * Modal paths: /workplace/:id/... or /shift/:id/...
 *
 * Examples of complete paths:
 * 1. Workplace modal from map: /shift-discovery/map/workplace/123/profile
 * 2. Shift modal on top of workplace: /shift-discovery/list/workplace/123/open-shifts/shift/456
 * 3. Direct shift modal from list: /shift-discovery/list/shift/456
 */
export const SHIFT_DISCOVERY_SHIFT_MODAL_PATH = `shift/:shiftId`;
export const SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH = `shift-booked/:shiftId`;
export const SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH = `shift-break/:shiftId/note/:noteId`;
export const SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH = `shift-undo-booking/:shiftId`;
export const SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH = `quiz/:shiftId`;

export const SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH = `workplace/:workplaceId/profile`;
export const SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH = `workplace/:workplaceId/open-shifts`;
export const SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH = `workplace/:workplaceId/policies`;
export const SHIFT_DISCOVERY_WORKPLACE_CHECK_IN_MODAL_PATH = `workplace/:workplaceId/check-in`;
export const SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH = `workplace/:workplaceId/reviews`;
export const SHIFT_DISCOVERY_WORKPLACE_REVIEWS_COMMENT_MODAL_PATH = `workplace/:workplaceId/reviews/comment/:commentId`;
export const SHIFT_DISCOVERY_WORKPLACE_LEAVE_REVIEW_MODAL_PATH = `workplace/:workplaceId/reviews/leave-review`;
