import { Box, Slide } from "@mui/material";
import { type ReactNode } from "react";

export interface SlideElementsTransitionProps {
  isSwitchedOn: boolean;
  offElement: ReactNode;
  onElement: ReactNode;
  timeout?: number;
  direction?: "horizontal" | "vertical";
  container?: Element | undefined;
}

export function SlideElementsTransition(props: SlideElementsTransitionProps) {
  const { isSwitchedOn, offElement, onElement, timeout, direction = "vertical", container } = props;

  return (
    <Box sx={{ position: "relative" }}>
      <Slide
        direction={direction === "horizontal" ? "left" : "up"}
        in={!isSwitchedOn}
        timeout={timeout}
        container={container}
        appear={false}
      >
        <Box sx={{ position: "absolute" }}>{offElement}</Box>
      </Slide>

      <Slide
        direction={direction === "horizontal" ? "right" : "down"}
        in={isSwitchedOn}
        timeout={timeout}
        container={container}
      >
        <Box>{onElement}</Box>
      </Slide>
    </Box>
  );
}
