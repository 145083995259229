import { isDefined } from "@clipboard-health/util-ts";
import { type Location } from "@src/appV2/lib/GoogleMaps";

interface ConstructGoogleMapUrlOptions {
  location: Location;
  formattedAddress?: string;
}

/**
 * Constructs a Google Maps URL for a given location
 * Documentation: https://developers.google.com/maps/documentation/urls/get-started#search-action
 */
export function constructGoogleMapUrl(options: ConstructGoogleMapUrlOptions): string {
  const { location, formattedAddress } = options;
  const baseUrl = "https://www.google.com/maps/search/";

  const query = isDefined(formattedAddress) ? formattedAddress : `${location.lat},${location.lng}`;

  // Encode the query and replace spaces with plus signs as per Google's convention
  const encodedQuery = encodeURIComponent(query).replace(/%20/g, "+");

  return `${baseUrl}?api=1&query=${encodedQuery}`;
}
