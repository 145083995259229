import { mergeSxProps } from "@clipboard-health/ui-react";
import { Skeleton, type SxProps, type Theme } from "@mui/material";

import { PILL_HEIGHT_MEDIUM, PILL_HEIGHT_SMALL } from "../theming/overrides/chip";
import type { PillSize } from "./Pill";

interface PillSkeletonProps {
  width?: string | number;
  height?: string | number;
  size?: PillSize;
  sx?: SxProps<Theme>;
}

const pillHeightBySize: Record<PillSize, string> = {
  small: PILL_HEIGHT_SMALL,
  medium: PILL_HEIGHT_MEDIUM,
};

const pillWidthBySize: Record<PillSize, number> = {
  small: 150,
  medium: 200,
};

export function PillSkeleton(props: PillSkeletonProps) {
  const { width, height, size = "medium", sx } = props;
  return (
    <Skeleton
      variant="rounded"
      width={width ?? pillWidthBySize[size]}
      height={height ?? pillHeightBySize[size]}
      sx={mergeSxProps({ borderRadius: (theme) => theme.borderRadius?.small }, sx)}
    />
  );
}
