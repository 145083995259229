import { Paper } from "@mui/material";
import { type ReactNode } from "react";

import { type PaperVariant } from "../theming/overrides/paper";
import { getSafeAreaPaddings } from "../utils/getSafeAreaPaddings";

interface Props {
  children: ReactNode;
  variant?: PaperVariant;
}

/**
 * This is minimalistic wrapper for a page. It doesn't enforce any particular layout or margins, but replaces IonPage (safe area insets)
 * New design pages have different headers so it also does not make sense to hardcode it here
 */
export function PageWrapper(props: Props) {
  const { children, variant = "primary" } = props;

  return (
    <Paper
      variant={variant}
      sx={{
        height: "100vh",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        // we don't have a bottom padding as all pages render bottom navigation which handles safe area inset
        ...getSafeAreaPaddings({ top: true, left: true, right: true }),
      }}
    >
      {children}
    </Paper>
  );
}
