import { Box, keyframes } from "@mui/material";

export const SPINNER_VARIANTS = ["primary", "secondary", "invert", "destructive"] as const;
export type SpinnerVariant = (typeof SPINNER_VARIANTS)[number];

export const SPINNER_SIZES = ["small", "large"] as const;
export type SpinnerSize = (typeof SPINNER_SIZES)[number];

interface LoadingSpinnerProps {
  size: SpinnerSize;
  variant?: SpinnerVariant;
}
const rotateKeyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const { size, variant = "primary" } = props;
  const pixelSize = size === "large" ? 40 : 24;
  const CENTER = pixelSize * 0.5;
  const OUTER_CIRCLE_RADIUS = pixelSize * 0.4;
  const INNER_CIRCLE_RADIUS = pixelSize * 0.3;
  const HEAD_CIRCLE_RADIUS = pixelSize * 0.05;
  // center + inner radius + head circle radius
  const HEAD_CIRCLE_CX = pixelSize * 0.85;
  const HEAD_CIRCLE_CY = pixelSize * 0.5;

  return (
    <Box
      sx={(theme) => {
        const spinnerColor = String(theme.palette.components?.spinner[variant]);
        const gradient = theme.palette.components?.spinner.generateGradient(spinnerColor);

        return {
          flexShrink: 0,
          position: "relative",
          width: pixelSize,
          height: pixelSize,
          borderRadius: "50%",
          background: gradient,
          maskImage: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${pixelSize} ${pixelSize}' width='${pixelSize}' height='${pixelSize}'><mask id='ringMask'><rect x='0' y='0' width='${pixelSize}' height='${pixelSize}' fill='black' /><circle cx='${CENTER}' cy='${CENTER}' r='${OUTER_CIRCLE_RADIUS}' fill='white' /><circle cx='${CENTER}' cy='${CENTER}' r='${INNER_CIRCLE_RADIUS}' fill='black' /></mask><rect x='0' y='0' width='${pixelSize}' height='${pixelSize}' fill='black' mask='url(%23ringMask)' /></svg>")`,
          animation: `${rotateKeyFrames} 0.75s linear infinite`,

          "& > svg > circle": {
            fill: spinnerColor,
          },
        };
      }}
    >
      <svg viewBox={`0 0 ${pixelSize} ${pixelSize}`} xmlns="http://www.w3.org/2000/svg">
        <circle cx={HEAD_CIRCLE_CX} cy={HEAD_CIRCLE_CY} r={HEAD_CIRCLE_RADIUS} />
      </svg>
    </Box>
  );
}
