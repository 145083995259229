import { Text } from "@clipboard-health/ui-react";
import { Paper } from "@mui/material";

interface PayInfoCardProps {
  /**
   * The formatted total pay for the shift.
   */
  totalPay: string;
  /**
   * The formatted hourly pay for the shift.
   */
  hourlyPay: string;
}

/**
 * A card that displays the total pay and hourly pay for a shift.
 */
export function PayInfoCard(props: PayInfoCardProps) {
  const { totalPay, hourlyPay } = props;
  return (
    <Paper
      variant="tertiary"
      sx={(theme) => ({
        width: "fit-content",
        border: `${String(theme.borderWidth?.thin)} solid ${String(theme.palette.border?.primary)}`,
        borderRadius: theme.borderRadius?.small,
        px: 5,
        py: 4,

        "& .MuiTypography-root": {
          textAlign: "right",
        },
      })}
    >
      <Text semibold variant="h5">
        {totalPay}
      </Text>
      <Text variant="body2" color={(theme) => theme.palette.text.secondary}>
        {hourlyPay} /hr
      </Text>
    </Paper>
  );
}
