import { mergeSxProps, Text } from "@clipboard-health/ui-react";
import { Chip, type ChipProps, type SxProps, type Theme } from "@mui/material";

import { CbhIcon, type IconType } from "./CbhIcon";

export const PILL_VARIANTS = ["filled", "outlined", "outlined-dark"] as const;
export const PILL_SIZES = ["small", "medium"] as const;

export type PillVariant = (typeof PILL_VARIANTS)[number];
export type PillSize = (typeof PILL_SIZES)[number];

type ColorFunction = (theme: Theme) => string | undefined;

interface BasePillProps {
  sx?: SxProps<Theme>;
  disabled?: boolean;
  size?: PillSize;
  color?: ColorFunction;
  labelColor?: ColorFunction;
  variant?: PillVariant;
}

interface PillWithLabel extends BasePillProps {
  label: React.ReactNode;
  iconType?: IconType;
}

interface PillWithIcon extends BasePillProps {
  label?: React.ReactNode;
  iconType: IconType;
}

type PillProps = PillWithLabel | PillWithIcon;

const pillVariantToChipVariant: Record<PillVariant, ChipProps["variant"]> = {
  filled: "filled",
  outlined: "outlined",
  "outlined-dark": "outlined",
};

const getStyleForVariant = (theme: Theme, variant: PillVariant, color?: ColorFunction) => {
  if (variant === "outlined-dark") {
    return { borderColor: color?.(theme) ?? theme.palette.primary.main };
  }

  if (!color) {
    return {};
  }

  if (variant === "outlined") {
    return { borderColor: color(theme) };
  }

  return { backgroundColor: color(theme) };
};

// TODO: Add Chip from MUI to eslint ignore pattern after we roll out the redesign
export function Pill(props: PillProps) {
  const {
    color,
    disabled,
    iconType,
    label,
    labelColor,
    size = "small",
    sx,
    variant = "filled",
  } = props;

  return (
    <Chip
      disabled={disabled}
      label={
        typeof label === "string" ? (
          <Text semibold variant="body2">
            {label}
          </Text>
        ) : (
          label
        )
      }
      icon={iconType ? <CbhIcon type={iconType} size="small" /> : undefined}
      sx={mergeSxProps(
        (theme) => ({
          ...getStyleForVariant(theme, variant, color),

          "& .MuiChip-icon, & .MuiChip-label, & .MuiTypography-root": {
            color: labelColor?.(theme),
          },
        }),
        sx
      )}
      variant={pillVariantToChipVariant[variant]}
      size={size}
    />
  );
}
