import { mergeSxProps } from "@clipboard-health/ui-react";
import { Paper, type PaperProps } from "@mui/material";

import { getSafeAreaPaddings } from "../utils/getSafeAreaPaddings";

export interface PageHeaderWrapperProps extends PaperProps {}

export function PageHeaderWrapper(props: PageHeaderWrapperProps) {
  const { sx, variant = "primary", component = "header", ...restProps } = props;

  return (
    <Paper
      component={component}
      variant={variant}
      sx={mergeSxProps(
        // by default our designs do not have padding on the top of the header so we only need safe area inset
        // however, to make it look better on web or devices with no notch we add a small fallback top padding
        (theme) => ({ ...getSafeAreaPaddings({ top: true, fallbackTop: theme.spacing(3) }) }),
        sx
      )}
      {...restProps}
    />
  );
}
