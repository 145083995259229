import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, ButtonBase, type ButtonBaseProps, type Theme } from "@mui/material";

import { ButtonInternalLink } from "./ButtonInternalLink";
import { CallToActionIcon } from "./CallToActionIcon";
import { CbhIcon, type IconType } from "./CbhIcon";

export const SELECTABLE_BOX_VARIANTS = ["primary", "secondary", "destructive", "success"] as const;
export type SelectableBoxVariant = (typeof SELECTABLE_BOX_VARIANTS)[number];

export interface SelectableBoxProps extends Omit<ButtonBaseProps, "onClick"> {
  variant?: SelectableBoxVariant;
  isSelected?: boolean;
  onToggleSelect?: (
    isSelected: boolean,
    // This extracts the event type from the onClick handler to ensure proper typing
    // The event type depends on the rendered element (button, link, etc) via LinkComponent
    event: NonNullable<ButtonBaseProps["onClick"]> extends (event: infer E) => any ? E : never
  ) => void;
  startIconType?: IconType;
  withCallToActionIcon?: boolean;
  href?: string;
}

function getSelectableBoxVariantStyles(variant: SelectableBoxVariant, theme: Theme) {
  switch (variant) {
    case "primary": {
      return {
        backgroundColor: theme.palette.background.tertiary,

        "&[aria-pressed=true]": {
          backgroundColor: theme.palette.surface?.primary,
          color: theme.palette.text.invertPrimary,
        },

        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
        },
      };
    }

    case "secondary": {
      return {
        backgroundColor: theme.palette.background.secondary,

        "&[aria-pressed=true]": {
          borderColor: theme.palette.primary.main,
        },

        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
          borderColor: "transparent",
        },
      };
    }

    case "destructive": {
      return {
        backgroundColor: theme.palette.background.tertiary,

        "&[aria-pressed=true]": {
          color: theme.palette.callout?.error.text,
          borderColor: theme.palette.callout?.error.border,
        },

        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
          borderColor: "transparent",
        },
      };
    }

    case "success": {
      return {
        backgroundColor: theme.palette.background.tertiary,

        "&[aria-pressed=true]": {
          color: theme.palette.callout?.success.text,
          borderColor: theme.palette.callout?.success.border,
        },

        "&.Mui-disabled": {
          color: theme.palette.text.disabled,
          borderColor: "transparent",
        },
      };
    }

    default: {
      return {};
    }
  }
}

/**
 * A button-based component that can toggle between selected and unselected states.
 *
 * Common use cases include:
 * - Selecting one option from a list of choices
 * - Highlighting a call-to-action option
 * - Toggling between active/inactive states
 *
 * The component supports different visual variants (primary, destructive, success)
 * and can be disabled when needed.
 */
export function SelectableBox(props: SelectableBoxProps) {
  const {
    variant = "primary",
    sx,
    isSelected = false,
    onToggleSelect,
    LinkComponent = ButtonInternalLink,
    startIconType,
    withCallToActionIcon = false,
    children,
    disabled,
    ...restProps
  } = props;

  return (
    <ButtonBase
      aria-pressed={isSelected}
      disabled={disabled}
      LinkComponent={LinkComponent}
      sx={mergeSxProps(
        (theme) => ({
          px: 6,
          py: 5,
          // using transparent border to avoid visual glitch when the component has border on selected state
          border: "1px solid transparent",
          borderRadius: theme.borderRadius?.small,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[2],
          fontSize: theme.typography.body2.fontSize,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          transition: theme.transitions.create(["background-color", "color", "border-color"], {
            duration: theme.transitions.duration.standard,
          }),
          ...getSelectableBoxVariantStyles(variant, theme),
        }),
        sx
      )}
      onClick={(event) => {
        if (!disabled && isDefined(onToggleSelect)) {
          onToggleSelect(!isSelected, event);
        }
      }}
      {...restProps}
    >
      {isDefined(startIconType) && <CbhIcon type={startIconType} sx={{ marginRight: 3 }} />}

      <Box sx={{ flex: 1, "&:not(:last-child)": { marginRight: 4 } }}>{children}</Box>

      {withCallToActionIcon && <CallToActionIcon sx={{ marginLeft: "auto" }} />}
    </ButtonBase>
  );
}
