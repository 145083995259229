import { isDefined } from "@clipboard-health/util-ts";
import { type Location } from "@src/appV2/lib/GoogleMaps";

interface ConstructAppleMapUrlOptions {
  location: Location;
  name?: string;
  formattedAddress?: string;
}

/**
 * Constructs an Apple Maps URL for a given location
 * Always includes coordinates for precise marker placement
 * Optionally includes address for search context and name for the pin label
 */
export function constructAppleMapUrl(options: ConstructAppleMapUrlOptions): string {
  const { location, name, formattedAddress } = options;
  const baseUrl = "http://maps.apple.com";
  const searchParams = new URLSearchParams();

  searchParams.set("ll", `${location.lat},${location.lng}`);

  if (isDefined(formattedAddress)) {
    searchParams.set("address", formattedAddress);
  }

  if (isDefined(name)) {
    searchParams.set("q", name);
  }

  return `${baseUrl}?${searchParams.toString()}`;
}
